<template>
    
    <div class="card">
        <crear_baremo :visible="visibleCrear" @update:visible="cerrarModal" @baremo-creado="handleBaremoCreado"/>
        <editar_baremo
            v-if="baremoSeleccionado"
            :id="baremoSeleccionado.datos.id_baremo"
            :datos="baremoSeleccionado"
            @update:visible="cerrarEditarBaremo"
            :visible="visibleEditar"
            @datos-actualizados="handleBaremoCreado"
        /> 
        <companias_baremo_clientes
            v-on:cambiolista="actualizarlista"
            v-if="companiaBaremoSeleccionado"
            :id="companiaBaremoSeleccionado.datos.id_baremo"
            :nombre="companiaBaremoSeleccionado.datos.nombre"
            @update:visible="cerrarCompaniasBaremo"
            :visible="visibleCompaniasCliente"
        /> 
        <baremo_operarios
            v-on:cambiolista="actualizarlista"
            v-if="companiaBaremoSeleccionado"
            :id="companiaBaremoSeleccionado.datos.id_baremo"
            :nombre="companiaBaremoSeleccionado.datos.nombre"
            @update:visible="cerrarBaremoOperario"
            :visible="visibleOperario"
        />
        <baremo_proveedores
            v-on:cambiolista="actualizarlista"
            v-if="companiaBaremoSeleccionado"
            :id="companiaBaremoSeleccionado.datos.id_baremo"
            :nombre="companiaBaremoSeleccionado.datos.nombre"
            @update:visible="cerrarBaremoProveedores"
            :visible="visibleProveedores"
        /> 
        <div class="card-body">
            <div class="row justify-content-between mb-3">
                <div>
                    <button class="btn-primary btn" @click="visibleCrear = true">Crear baremo</button>
                </div>
                <div class="row justify-content-end align-items-center">
                    <div>
                        <label for="buscar">Buscar: </label>
                    </div>
                    <div style="margin-left: 10px;">
                        <input type="search" name="buscar" class="form-control" v-model="globalFilter" aria-controls="tabla_chat"> 
                    </div>
                </div>
            </div>
            <DataTable 
            stripedRows
            v-model:filters="filters" 
            :value="chatsFiltrados"
            responsiveLayout="scroll"
            paginator 
            :rows="10" 
            dataKey="datos.id_baremo" 
            :globalFilterFields="['datos.nombre', 'datos.tipo', 'datos.fecha_inicio', 'datos.fecha_fin']">
            
                <template #empty> No hay baremos disponibles. </template>

                <!-- Column Definitions -->
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Nombre</strong>
                            <button @click="toggleOrden('nombre')" :aria-sort="nombreOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="nombreIcon"></i>
                            </button>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.datos.nombre }}
                    </template>
                </Column>
                
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Tipo</strong>
                            <button @click="toggleOrden('tipo')" :aria-sort="tipoOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="tipoIcon"></i>
                            </button>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.datos.tipo }}
                    </template>
                </Column>
                
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Fecha inicio</strong>
                            <button @click="toggleOrden('fecha_inicio')" :aria-sort="fechaInicioOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="fechaInicioIcon"></i>
                            </button>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.datos.fecha_inicio }}
                    </template>
                </Column>

                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Fecha fin</strong>
                            <button @click="toggleOrden('fecha_fin')" :aria-sort="fechaFinOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="fechaFinIcon"></i>
                            </button>
                        </div>
                    </template>
                    <template #body="{ data }">
                        {{ data.datos.fecha_fin }}
                    </template>
                </Column>

                <Column>
                    <template #header>
                    </template>
                    <template #body="{ data }">
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="editar(data)">Editar</button>
                    </template>
                </Column>

                <Column>
                    <template #header>
                    </template>
                    <template #body="{ data }">
                        <button v-if="(data.datos.tipo.toLowerCase() == 'cliente')" class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="verCompanias(data)">Compañías {{ companias[data.datos.id_baremo] }}</button>
                        <button v-if="(data.datos.tipo.toLowerCase() == 'proveedor')" class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="verCompanias(data)">Proveedores {{ companias[data.datos.id_baremo] }}</button>
                        <button v-if="(data.datos.tipo.toLowerCase() == 'operario')" class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="verCompanias(data)">Operarios {{ companias[data.datos.id_baremo] }}</button>
                    </template>
                </Column>
            </DataTable> 
        </div>
    </div>
</template>

<script>
import baremo_operarios from './baremo_operarios.vue';
import editar_baremo from './editar_baremo.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import crear_baremo from './crear_baremo.vue';
import companias_baremo_clientes from './companias_baremo_clientes.vue';
import baremo_proveedores from './baremo_proveedores.vue';
export default {
    props: ["lista"],
    data() {
        return {
            visibleProveedores:false,
            visibleOperario:false,
            companias:[],
            visibleCrear: false,
            visibleCompaniasCliente: false,
            visibleCompaniasProveedor:false,
            globalFilter: null,
            ordenarPor: 'fecha_inicio',
            visibleEditar: false,
            companiaBaremoSeleccionado:null,
            baremoSeleccionado: null,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            nombreOrdenAscendente: false,
            tipoOrdenAscendente: false,
            fechaInicioOrdenAscendente: true,
            fechaFinOrdenAscendente: false,
            nombreIcon: 'fas fa-sort-up',
            tipoIcon: 'fas fa-sort-up',
            fechaInicioIcon: 'fas fa-sort-down',
            fechaFinIcon: 'fas fa-sort-up',
        };
    },
    components: {
        DataTable,
        Column,
        crear_baremo,
        editar_baremo,
        companias_baremo_clientes,
        baremo_operarios,
        baremo_proveedores,
    },
    computed: {        
        chatsFiltrados() {            
            if (this.$props.lista) {
                let filtrados = this.$props.lista;
                filtrados.sort((a, b) => {
                    if(this.ordenarPor == 'fecha_inicio') {                        
                        const fechaA = this.parseDate(a.datos.fecha_inicio);
                        const fechaB = this.parseDate(b.datos.fecha_inicio);
                        return this.fechaInicioOrdenAscendente ? fechaB - fechaA : fechaA - fechaB;                        
                    }else if(this.ordenarPor == 'fecha_fin') {
                        const fechaA = this.parseDate(a.datos.fecha_fin);
                        const fechaB = this.parseDate(b.datos.fecha_fin);
                        return this.fechaFinOrdenAscendente ? fechaB - fechaA : fechaA - fechaB; 
                    } else if (this.ordenarPor === 'nombre') {
                        const valorA = a.datos.nombre.toLowerCase();
                        const valorB = b.datos.nombre.toLowerCase();
                        return this.nombreOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB);
                    } else {
                        const valorA = a.datos.tipo.toLowerCase();
                        const valorB = b.datos.tipo.toLowerCase();
                        return this.tipoOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB); 
                    }
                });
                return filtrados;
            } else {             
                return [];
            }
        }
    },
    methods: {
        actualizarlista(){
            this.$emit('cambiolista');
        },
        verCompanias(baremo){
            this.companiaBaremoSeleccionado = baremo;
            if(baremo.datos.tipo.toLowerCase() == "cliente"){
                this.visibleCompaniasCliente = true;
            }
            if(baremo.datos.tipo.toLowerCase() == "operario"){
                this.visibleOperario = true;
            }
            if(baremo.datos.tipo.toLowerCase() == "proveedor"){
                this.visibleProveedores = true;
            }
        },
        editar(baremo) {
            this.baremoSeleccionado = baremo;
            this.visibleEditar = true;
        },
        handleBaremoCreado(){
            this.$parent.obtenerListas();
        },
        cerrarModal(){
            this.visibleCrear = false;
        },
        cerrarEditarBaremo() {
            this.visibleEditar = false;
            this.baremoSeleccionado = null;
        },
        cerrarCompaniasBaremo(){
            this.visibleCompanias = false;
            this.companiaBaremoSeleccionado = null;
        },
        cerrarBaremoProveedores(){
            this.visibleProveedores = false;
            this.companiaBaremoSeleccionado = null;
        },
        cerrarBaremoOperario(){
            this.visibleOperario = false;
            this.companiaBaremoSeleccionado = null;
        },
        parseDate(dateString) {
            const [day, month, year] = dateString.split('/');
            return new Date(`${year}-${month}-${day}`);
        },
        toggleOrden(campo) {
            this.ordenarPor = campo;
            if(campo == 'fecha_inicio'){
                this.fechaInicioOrdenAscendente = !this.fechaInicioOrdenAscendente;
                this.fechaInicioIcon = this.fechaInicioOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaFinOrdenAscendente = false;
                this.fechaFinIcon = 'fas fa-sort-up';
                this.nombreOrdenAscendente = false;
                this.nombreIcon = 'fas fa-sort-up';
                this.tipoOrdenAscendente = false;
                this.tipoIcon = 'fas fa-sort-up';
            }else if(campo == 'fecha_fin'){
                this.fechaFinOrdenAscendente = !this.fechaFinOrdenAscendente;
                this.fechaFinIcon = this.fechaFinOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaInicioOrdenAscendente = false;
                this.fechaInicioIcon = 'fas fa-sort-up';
                this.nombreOrdenAscendente = false;
                this.nombreIcon = 'fas fa-sort-up';
                this.tipoOrdenAscendente = false;
                this.tipoIcon = 'fas fa-sort-up';
            }else if(campo == 'nombre'){
                this.nombreOrdenAscendente = !this.nombreOrdenAscendente;
                this.nombreIcon = this.nombreOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaInicioOrdenAscendente = false;
                this.fechaInicioIcon = 'fas fa-sort-up';
                this.fechaFinOrdenAscendente = false;
                this.fechaFinIcon = 'fas fa-sort-up';
                this.tipoOrdenAscendente = false;
                this.tipoIcon = 'fas fa-sort-up';
            }else{
                this.tipoOrdenAscendente = !this.tipoOrdenAscendente;
                this.tipoIcon = this.tipoOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaInicioOrdenAscendente = false;
                this.fechaInicioIcon = 'fas fa-sort-up';
                this.fechaFinOrdenAscendente = false;
                this.fechaFinIcon = 'fas fa-sort-up';
                this.nombreOrdenAscendente = false;
                this.nombreIcon = 'fas fa-sort-up';
            }
        },
        getOrdenAscendente(campo) {
            return this[`ordenAscendente${campo.charAt(0).toUpperCase() + campo.slice(1)}`];
        },
        calcularTrabajos(){
            for(let baremo of this.lista){
                var comps = [];
                if(baremo.datos.tipo.toLowerCase()=="cliente"){
                    comps = baremo.id_companyias;
                }
                if(baremo.datos.tipo.toLowerCase()=="operario"){
                    comps = baremo.id_operarios;
                }
                if(baremo.datos.tipo.toLowerCase()=="proveedor"){
                    comps = baremo.id_proveedores;
                }
                if(comps){
                    this.companias[baremo.datos.id_baremo]=comps.length;
                }
            }
        }
    },
    mounted() {        
    },
    watch: {
        globalFilter(newFilter) {
            this.filters.global.value = newFilter;
        },
        lista(){
            this.calcularTrabajos();
        }
    }
};
</script>
<style scoped>
.table-responsive {
    overflow-x: auto;
}
</style>